import React, { useState } from 'react'
import { Input, Button, IconButton } from 'theme-ui'
import { Autocomplete, Dialog } from 'evergreen-ui'

const IngredientDialog = (props) => {
  const { items, onChange, onRemove, selectedItem } = props
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <IconButton
        aria-label="Link to Recipe"
        onClick={(e) => setModalOpen(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          fill="white"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
            fill="white"
          />
        </svg>
      </IconButton>
      <Dialog
        isShown={modalOpen}
        title="Link to Ingredient"
        onCloseComplete={() => setModalOpen(false)}
        hasFooter={false}
      >
        <Button onClick={onRemove}>Remove Link</Button>

        <Autocomplete
          title="Recipes"
          onChange={onChange}
          itemToString={(item) => item && item.name}
          items={items}
        >
          {(props) => {
            const { getInputProps, getRef } = props
            return (
              <Input
                placeholder="Recipe"
                value={selectedItem}
                ref={getRef}
                {...getInputProps()}
              />
            )
          }}
        </Autocomplete>
      </Dialog>
    </>
  )
}

export default IngredientDialog
