import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Flex } from 'theme-ui'
import Header from './Header'
import Recipes from './Recipes'
import RecipeSingle from './RecipeSingle'
import RecipeEditor from './RecipeEditor'
import Dashboard from './Dashboard'

const Main = () => {
  return (
    <div>
      <Header />
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/recipes" component={Recipes} />
        <Route exact path="/recipes/editor/" component={RecipeEditor} />
        <Route path="/recipes/editor/:recipeId" component={RecipeEditor} />
        <Route path="/recipes/:recipeId" component={RecipeSingle} />
      </Switch>
    </div>
  )
}

export default Main
