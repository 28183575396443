import React from 'react'
import { Flex, Box, Button } from 'theme-ui'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

const Dashboard = () => {
  useFirestoreConnect([{ collection: 'recipes' }])
  const recipes = useSelector((state) => state.firestore.ordered.recipes)

  const handleDownloadRecipes = () => {
    let dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(recipes))
    let downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataStr)
    downloadAnchorNode.setAttribute('download', 'recipes.json')
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }
  return (
    <Flex
      sx={{
        m: 4,
        a: {
          color: 'text',
        },
      }}
    >
      <Box>
        <Button onClick={handleDownloadRecipes}>Download Recipes</Button>
      </Box>
    </Flex>
  )
}

export default Dashboard
