import React, { useEffect, useState, useRef } from 'react'
import {
  Box,
  Heading,
  Input,
  Field,
  Button,
  Flex,
  Checkbox,
  IconButton,
  Textarea,
} from 'theme-ui'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import { initRecipe } from '../utils/recipes'
import { Label } from 'evergreen-ui'
import InputTags from './InputTags'
import IngredientDialog from './IngredientDialog'

const RecipeEditor = () => {
  const { recipeId } = useParams()
  const [recipe, setRecipe] = useState(initRecipe)
  const [tagSuggestions, setTagSuggestions] = useState([])
  const firebase = useFirestore()
  const history = useHistory()
  const tagsRef = useRef()
  const [recipes, setRecipes] = useState([])

  useFirestoreConnect([{ collection: 'recipes' }])

  const recipeFromDB = useSelector(
    (state) =>
      state.firestore.data.recipes && state.firestore.data.recipes[recipeId]
  )

  const recipesFromDB = useSelector((state) => state.firestore.ordered.recipes)

  useEffect(() => {
    setRecipes(recipesFromDB)
    setRecipe({ ...initRecipe, ...recipeFromDB })
  }, [recipeFromDB, recipesFromDB])

  const handleSubmit = () => {
    if (recipeId) {
      firebase
        .collection('recipes')
        .doc(recipeId)
        .update(recipe)
        .then(() => {
          history.push(`/recipes/${recipeId}`)
        })
    } else {
      firebase
        .collection('recipes')
        .add(recipe)
        .then((docRef) => {
          history.push(`/recipes/${docRef.id}`)
        })
    }
  }

  if (!recipe) return <Box>Loading...</Box>

  const removeItem = (e, what, index) => {
    e.preventDefault()
    const whatArray = [...recipe[what]]
    whatArray.splice(index, 1)
    setRecipe({
      ...recipe,
      [what]: [...whatArray],
    })
  }

  const addItem = (what) => {
    const initIngredient = {
      amount: '',
      measure: '',
      name: '',
    }
    const initWhat = what === 'ingredients' ? initIngredient : ''
    setRecipe((prevState) => {
      return { ...prevState, [what]: [...recipe[what], initWhat] }
    })
  }

  const updateField = (field, value) => {
    setRecipe((prevState) => {
      return {
        ...prevState,
        [field]: value,
      }
    })
  }

  const updateItemInArray = (value, what, field, index) => {
    let whats = (recipe[what] = [...recipe[what]])

    if (field) {
      whats[index] = { ...whats[index], [field]: value }
    } else {
      whats[index] = value
    }

    setRecipe((prevState) => ({
      ...prevState,
      [what]: whats,
    }))
  }

  // Tags
  const handleTags = (tags) => {
    setRecipe((prevState) => ({
      ...prevState,
      tags: tags,
    }))
  }

  return (
    <Box sx={{ m: 4, label: { color: 'text' } }}>
      <Heading as={'h1'} sx={{ mb: 2 }}>
        Recipe Editor
      </Heading>

      <Box sx={{ mb: 4 }}>
        <Field
          label="Name"
          name="name"
          value={recipe.name}
          onChange={(e) => updateField('name', e.target.value)}
        />
      </Box>

      <Box sx={{ mb: 4, label: { display: 'flex' } }}>
        <Heading as={'h2'} sx={{ mb: 3 }}>
          Important Settings
        </Heading>

        <Flex sx={{ mb: 2 }}>
          <Label>
            <Checkbox
              onChange={(e) =>
                setRecipe({ ...recipe, ...{ active: e.target.checked } })
              }
            />
            Active
          </Label>
        </Flex>

        <Flex sx={{ mb: 2 }}>
          <Label>
            <Checkbox
              onChange={(e) =>
                setRecipe({
                  ...recipe,
                  ...{ subscriptionRequire: e.target.checked },
                })
              }
            />
            Subscription Required
          </Label>
        </Flex>

        <Flex sx={{ mb: 2 }}>
          <Label>
            <Checkbox
              onChange={(e) =>
                setRecipe({
                  ...recipe,
                  ...{ ingredient: e.target.checked },
                })
              }
            />
            This is an ingredient
          </Label>
        </Flex>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Label>Description</Label>
        <Textarea
          label="Description"
          name="description"
          value={recipe.description}
          onChange={(e) => updateField('description', e.target.value)}
        />
      </Box>

      <Box sx={{ mb: 4 }}>
        <Heading as={'h2'} sx={{ mb: 2 }}>
          Ingredients
        </Heading>
        {recipe.ingredients.map((ingredient, index) => (
          <Flex key={index} sx={{ mb: 2 }}>
            <Field
              label="Amount"
              name={`amount_${index}`}
              value={ingredient.amount}
              onChange={(e) =>
                updateItemInArray(
                  e.target.value,
                  'ingredients',
                  'amount',
                  index
                )
              }
            />
            <Field
              label="Measure"
              name={`measure_${index}`}
              value={ingredient.measure}
              onChange={(e) =>
                updateItemInArray(
                  e.target.value,
                  'ingredients',
                  'measure',
                  index
                )
              }
            />
            <Box sx={{ flex: 1 }}>
              <Label htmlFor={`name_${index}`}>Name</Label>
              <Input
                sx={{ width: '100%' }}
                name={`name_${index}`}
                value={ingredient.name}
                onChange={(e) =>
                  updateItemInArray(
                    e.target.value,
                    'ingredients',
                    'name',
                    index
                  )
                }
              />
            </Box>

            <Box>
              <IngredientDialog
                items={recipes}
                onChange={(item) =>
                  updateItemInArray(item.id, 'ingredients', 'link', index)
                }
                onRemove={() =>
                  updateItemInArray(null, 'ingredients', 'link', index)
                }
                selectedItem={
                  ingredient.link &&
                  recipes.find(({ id }) => id === ingredient.link).name
                }
              />
            </Box>

            <Flex sx={{ m: 2, mt: 'auto' }}>
              <IconButton
                aria-label="Remove Ingredient"
                onClick={(e) => removeItem(e, 'ingredients', index)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  width="18px"
                  height="18px"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" />
                </svg>
              </IconButton>
            </Flex>
          </Flex>
        ))}
        <Button onClick={() => addItem('ingredients')}>Add Ingredient</Button>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Heading as={'h2'} sx={{ mb: 2 }}>
          Steps
        </Heading>
        {recipe.steps.map((step, index) => (
          <Flex key={index} sx={{ mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Label htmlFor={`step_${index}`}>Step {index + 1}</Label>
              <Textarea
                sx={{ width: '100%' }}
                name={`step_${index}`}
                value={step}
                onChange={(e) =>
                  updateItemInArray(
                    e.currentTarget.value,
                    'steps',
                    false,
                    index
                  )
                }
              />
            </Box>
            <Flex sx={{ m: 2 }}>
              <IconButton
                aria-label="Remove Ingredient"
                onClick={(e) => removeItem(e, 'steps', index)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  width="18px"
                  height="18px"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" />
                </svg>
              </IconButton>
            </Flex>
          </Flex>
        ))}
        <Button onClick={() => addItem('steps')}>Add Step</Button>
      </Box>

      <Box>
        <Label>Tags</Label>
        <InputTags recipeTags={recipe.tags} onChange={handleTags} />
      </Box>

      <Button onClick={handleSubmit}>Save</Button>
    </Box>
  )
}

export default RecipeEditor
