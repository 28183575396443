import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './components/App'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'
import { ThemeProvider } from 'theme-ui'
import theme from './theme'
import store from './store'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

const rrfProps = {
  firebase,
  config: {
    serProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  },
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.firestore()

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth)
  if (!isLoaded(auth)) return <div>splash screen...</div>
  return children
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
)
