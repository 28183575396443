import React from 'react'
import { useSelector } from 'react-redux'
import Home from './Home'
import Login from './Login'

const Main = () => {
  const auth = useSelector((state) => state.firebase.auth)

  return (
    <div>
      {!auth.isEmpty && !auth.isLoaded ? (
        <div>Loading...</div>
      ) : !auth.isEmpty ? (
        <Home />
      ) : (
        <Login />
      )}
    </div>
  )
}

export default Main
