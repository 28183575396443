import React from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Flex, Button } from 'theme-ui'
import { Link } from 'react-router-dom'
import { useColorMode } from 'theme-ui'

const Header = () => {
  const firebase = useFirebase()
  const [colorMode, setColorMode] = useColorMode()

  const handleSignOut = () => {
    firebase.auth().signOut()
  }

  return (
    <Flex
      sx={{
        px: 4,
        py: 2,
        justifyContent: 'space-between',
        borderBottom: '1px solid',
        borderColor: 'primary',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          a: {
            mr: 3,
            color: 'primary',
            textDecoration: 'none',
          },
        }}
      >
        <Link to="/">Home</Link>
        <Link to="/recipes">Recipes</Link>
      </Flex>

      <Flex>
        <Button
          sx={{ mr: 2 }}
          onClick={() => {
            setColorMode(colorMode === 'default' ? 'dark' : 'default')
          }}
        >
          Toggle {colorMode === 'default' ? 'Dark' : 'Light'}
        </Button>
        <Button variant="secondary" onClick={handleSignOut}>
          Sign Out
        </Button>
      </Flex>
    </Flex>
  )
}

export default Header
