import React from 'react'
import { Box, Heading } from 'theme-ui'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'

const RecipeSingle = () => {
  const { recipeId } = useParams()

  useFirestoreConnect([{ collection: 'recipes', doc: recipeId }])

  const recipe = useSelector(
    ({ firestore: { data } }) => data.recipes && data.recipes[recipeId]
  )

  if (!recipe) return <Box>Loading...</Box>

  return (
    <Box sx={{ m: 4 }}>
      <Box
        sx={{
          a: {
            color: 'text',
          },
        }}
      >
        <Link to={`/recipes/editor/${recipeId}`}>Edit</Link>
      </Box>
      <Heading as="h1">{recipe.name}</Heading>
      <p>{recipe.description}</p>

      <Heading>Ingredients</Heading>
      {recipe.ingredients &&
        recipe.ingredients.map((ingredient, index) => (
          <p key={index}>
            {ingredient.amount} {ingredient.measure} {ingredient.name}
          </p>
        ))}

      <Box>
        <Heading>Steps</Heading>
        {recipe.steps &&
          recipe.steps.map((step, index) => <p key={index}>{step}</p>)}
      </Box>

      <Box>
        <Box>Active: {recipe.active ? 'yes' : 'no'}</Box>
        <Box>
          Subscription Required: {recipe.subscriptionRequired ? 'yes' : 'no'}
        </Box>
      </Box>
    </Box>
  )
}

export default RecipeSingle
