import { createStore, combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
})

const store = createStore(rootReducer, composeWithDevTools())
export default store
