import React, { useState } from 'react'
import {
  useFirebase,
  // isLoaded, isEmpty
} from 'react-redux-firebase'
import { Flex, Button, Input } from 'theme-ui'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [hasError, setHasError] = useState(false)

  const firebase = useFirebase()

  const handleSubmit = (e) => {
    e.preventDefault()
    setHasError(false)

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        setHasError(true)
        setErrorMsg(error.message)
      })
  }

  return (
    <Flex
      sx={{
        minHeight: '100vh',
        width: '100vw',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <form onSubmit={handleSubmit} noValidate>
        {hasError && <div>{errorMsg}</div>}
        <Input
          sx={{ mb: 2 }}
          type="email"
          id="email"
          name="email"
          placeholder="Your e-mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          sx={{ mb: 2 }}
          type="password"
          id="password"
          name="password"
          placeholder="Your password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit">Sign In</Button>
      </form>
    </Flex>
  )
}

export default Login
