import React, { useState, useEffect, useRef } from 'react'

const InputTag = (props) => {
  const { recipeTags, onChange } = props
  const [tags, setTags] = useState(recipeTags)
  const tagInputRef = useRef()

  useEffect(() => {
    onChange(tags)
  }, [tags])

  const removeTag = (i) => {
    const newTags = [...tags]
    newTags.splice(i, 1)
    setTags(newTags)
  }

  const inputKeyDown = (e) => {
    const val = e.target.value

    if (e.key === 'Enter' && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return
      }
      setTags([...tags, val])
      tagInputRef.current.value = ''
    } else if (e.key === 'Backspace' && !val) {
      removeTag(tags.length - 1)
    }
  }

  return (
    <div className="input-tag">
      <ul className="input-tag__tags">
        {tags.map((tag, i) => (
          <li key={tag}>
            {tag}
            <button type="button" onClick={() => removeTag(i)}>
              -
            </button>
          </li>
        ))}
        <li className="input-tag__tags__input">
          <input type="text" ref={tagInputRef} onKeyDown={inputKeyDown} />
        </li>
      </ul>
    </div>
  )
}

export default InputTag
