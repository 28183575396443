import React from 'react'
import { Flex, Box } from 'theme-ui'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'

const Recipes = () => {
  useFirestoreConnect([{ collection: 'recipes' }])
  const recipes = useSelector((state) => state.firestore.ordered.recipes)

  const AddNewRow = () => {
    return (
      <tr>
        <td>
          <Link to={`/recipes/editor/`}>Add New Recipe</Link>
        </td>
      </tr>
    )
  }

  const RecipeRows = () => {
    const rows = (recipes || []).map((recipe) => (
      <tr key={recipe.id}>
        <td>
          <Link to={`/recipes/${recipe.id}`}>{recipe.name}</Link>
        </td>
        <td>{recipe.subscriptionRequired || ''}</td>
        <td>{recipe.active || 'status'}</td>
        <td>
          <Link to={`/recipes/editor/${recipe.id}`}>Edit</Link>
        </td>
      </tr>
    ))

    return rows
  }

  return (
    <Flex
      sx={{
        m: 4,
        a: {
          color: 'text',
        },
      }}
    >
      <Box
        as="table"
        sx={{
          borderCollapse: 'collapse',
          width: '100%',
          'th,td': {
            p: 2,
            textAlign: 'left',
          },
          thead: {
            color: 'text',
          },
          'tr::nth-of-type(even)': {
            backgroundColor: 'muted',
          },
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Subscription Required</th>
            <th>Active</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <AddNewRow />
          <RecipeRows />
        </tbody>
      </Box>
    </Flex>
  )
}

export default Recipes
