export const initRecipe = {
  name: '',
  description: '',
  ingredients: [],
  steps: [],
  tags: [],
  active: false,
  subscriptionRequired: false,
  author: null,
  collections: [],
}
